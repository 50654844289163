html, body {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.title {
  margin: 20px auto 0;
  text-align: center;
}

.links {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.links .link {
  margin-top: 20px;
}

.component {
  height: 100%;
  width: 100%;
  display: flex;
}

#background{
  width: 100%;
  height: auto;
}

.component.first-component {
  position: relative;
}

.first-component--inside-scroller {
  position: absolute;
  right: 20px;
  top: 100px;
}

.component.second-component {
  background-color: #20334B;
}

.component.third-component {
  background-color: #b4C8E2;
}

.component.fourth-component {

  background-color: #E8F1FC;
}

#fourth-component {
  margin: 20px;
  padding: 40px;
}

h2 {
  margin: auto;
  text-align: left;
}

p{
  margin-left: 53px;
  margin-top: -18px;
}

.pagination-additional-class {
  margin: 0;
  position: fixed;
  top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.pagination-additional-class > li:not(:last-of-type) > a, .pagination-additional-class > li:not(:last-of-type) > span {
  margin-right: 50px;
}

.pager li>a, .pager li>span {
  padding: 20px;
  border-radius: 30px;
}

input[type=text], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit] {
  background-color: #b4C8E2;
  color: white;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color:  #b4C8E2;
}

/* Style the container/contact section */
.container {
  border-radius: 5px;
  padding: 10px;
}

/* Create two columns that float next to eachother */
.column {
  float: left;
  width: 50%;
  margin-top: 6px;
  padding: 20px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column, input[type=submit] {
    width: 100%;
    margin-top: 0;
  }
}

@media screen and (min-width: 600px) {
  .only-mobile {
    display: none;
  }
  .only-desktop {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .only-mobile {
    display: block;
  }
  .only-desktop {
    display: none;
  }
}